import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import Top from './Top'
import Latest from './Latest'
import National from './National';
import International from './International';
import Health from './Health';
import Science from './Science';
import Sports from './Sports';
import {Helmet} from "react-helmet";

const Home =()=>{
    const [topData, setTopData] = useState({
            id: null,
            title: '',
            byline: '',
            content: '',
            image: '',
            slug:'',
            image_caption: ''
    });
    const [scDatt, setScienData] = useState({
        id: null,
        title: '',
        byline: '',
        content: '',
        image: '',
        slug:'',
        image_caption: ''
    });
    const [sptDatt, setSportsData] = useState({
        id: null,
        title: '',
        byline: '',
        content: '',
        image: '',
        slug:'',
        image_caption: ''
    });
    const [helDatat, setHelllData] = useState({
        id: null,
        title: '',
        byline: '',
        content: '',
        image: '',
        slug:'',
        image_caption: ''
    });
    const [intDatta, setIntData] = useState({
        id: null,
        title: '',
        byline: '',
        content: '',
        image: '',
        slug:'',
        image_caption: ''
    });   
    const [latData, setLatestData] = useState({
        id: null,
        title: '',
        byline: '',
        content: '',
        image: '',
        slug:'',
        image_caption: ''
    }); 
    const [bussData, setBussData] = useState({
        id: null,
        title: '',
        byline: '',
        content: '',
        image: '',
        slug:'',
        image_caption: ''
    });
    const [enterData, setEntertainmentData] = useState({
        id: null,
        title: '',
        byline: '',
        content: '',
        image: '',
        slug:'',
        image_caption: ''
    });  
    const [natList, setNationalListData] = useState({
        id: null,
        title: '',
        byline: '',
        content: '',
        image: '',
        slug:'',
        image_caption: ''
    });
    const topSData =()=>{
        axios
        .get(
        `https://cms.iansnews.in/api/news/portals/?language=english&website=1&top_news=true`
        )
        .then((res) => {
        const topStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 5).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setTopData(topStories);
        });
    }

    const LatestData =()=>{
        axios
        .get(
        `https://cms.iansnews.in/api/news/portals/?language=english&website=2`
        )
        .then((res) => {
        const latestStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 4).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setLatestData(latestStories);
        });
    }

    const BusinData =()=>{
        axios
        .get(
        `https://cms.iansnews.in/api/news/portals/?language=english&website=2`
        )
        .then((res) => {
        const busStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 6).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setBussData(busStories);
        });
    }    
    const NationalData =()=>{
        axios
        .get(
        `https://cms.iansnews.in/api/news/portals/?language=english&website=2&tags=national`
        )
        .then((res) => {
        const nationalStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 5).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setNationalListData(nationalStories);
        });
    }
    const EntertainmentData =()=>{
        axios
        .get(
        `https://cms.iansnews.in/api/news/portals/?language=english&website=2&tags=entertainment`
        )
        .then((res) => {
        const entertainmentStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 5).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setEntertainmentData(entertainmentStories);
        });
    }  
     const ScienceData =()=>{
        const scienceApi = "https://cms.iansnews.in/api/news/portals/?language=english&website=2&tags=science-tech";
        axios
        .get(
        scienceApi
        )
        .then((res) => {
        const sciStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 4).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setScienData(sciStories);
        });
    }  
    const SportsData =()=>{
        const spotApi = "https://cms.iansnews.in/api/news/portals/?language=english&website=2&tags=sports";
        axios
        .get(
            spotApi
        )
        .then((res) => {
        const sportssStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 4).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setSportsData(sportssStories);
        });
    }      
    const IntData =()=>{
        axios
        .get(
        `https://cms.iansnews.in/api/news/portals/?language=english&website=2&tags=international`
        )
        .then((res) => {
        const intStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 7).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setIntData(intStories);
        });
    } 
    const HealthhData =()=>{
        const healtApi = `https://cms.iansnews.in/api/news/portals/?language=english&website=2&tags=health-medicine`
        axios
        .get(
            healtApi
        )
        .then((res) => {
        const heallStories =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.slice(0, 7).map((item) => {
            return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                slug:'/'+item.slug,
                image_caption: item.image_caption,
            };
            });
        setHelllData(heallStories);
        });
    }        
    useEffect(() => {
        topSData();
        LatestData();
        NationalData();
        EntertainmentData();
        IntData();
        BusinData();
        ScienceData();
        SportsData();
        HealthhData();
      }, []);
       
    return(
        <>
        <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <link rel="icon" href="icon-new.ico" type="image/x-icon" />
        <link rel="shortcut icon" href="/theme/bollywood/images/favicon.ico" type="image/x-icon" />
        <title>IANS LIVE</title>
        <meta data-meta-title="IANS LIVE" name="keywords" content="IANS LIVE, IANS LIVE News, latest updates, live updates, business updates, Indian news, online news portal, Bollywood updates, breaking headlines, current events, global news, sports coverage, latest India updates, news highlights, news platform, top stories, India updates, entertainment buzz, national affairs, political happenings" />
        <meta data-meta-title="IANS LIVE" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
        </Helmet>
        <div id="wrapper" className="wrapper">
            <div id="main_content" className="footer-fixed">
            <Header />
            <main>
                <Top  top={topData}/>
                <Latest lat={latData}/>
                <National listData ={natList} enter={enterData} data={bussData}/>
                <section className="section-padding rt-main-post-section bg--gray">
                    <div className="container">
                        <div className="row gutter-30">
                            <International  data={intDatta}/>
                            <Sports datap={sptDatt}/>
                        </div>
                    </div>
                </section>
                <section className="section-padding">
                    <div className="container">
                        <div className="row gutter-24 sticky-coloum-wrap">
                            <Science data={scDatt}/> 
                            <Health hData={helDatat}/>                       
                            
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
            </div>
            <a href="javascript:void(0)" id="back-to-top">
            <i className="fas fa-angle-double-up"></i>
            </a>
        </div>        
        </>
    )
}

export default Home