import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import Sidebar from './Sidebar';
import {Helmet} from "react-helmet";

const Detail = () =>{
const {newsId} = useParams();
const [isLoading, setIsLoading] = useState(false);
const [paramValue, setParamValue] = useState(null);
const [newsData, setName] = useState([]);
const [collectionData, setCollectionData] = useState([]);
const [slideData, setSlideData] = useState([]);
const [latData, setLatestData] = useState({
   id: null,
   title: '',
   byline: '',
   content: '',
   image: '',
   slug:'',
   image_caption: ''
});
const LatestData =()=>{
   axios
   .get(
   `https://cms.iansnews.in/api/news/portals/?language=english&website=2`
   )
   .then((res) => {
   const latestStories =
       res.data.results &&
       res.data.results.length > 0 &&
       res.data.results.slice(0, 8).map((item) => {
       return {
           byline: item.byline,
           content: item.short_desc,
           id: item.id,
           image: item.thumbnail,
           title: item.title,
           slug:'/'+item.slug,
           image_caption: item.image_caption,
       };
       });
   setLatestData(latestStories);
   });
}
const [isVisible, setIsVisible] = useState(false);

const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  const getData = async() =>{
   const apiD= `https://cms.iansnews.in/api/news/portals/${newsId}`
   axios.get(apiD)
.then(res =>
  {

   let main_content_string = res.data.results[0].content;
   let iansIndex = main_content_string.indexOf("--IANS") ? main_content_string.indexOf("--IANS") : main_content_string;
   let new_main_content_string = main_content_string.substring(0, iansIndex);
 
 
   setCollectionData({
   byline: res.data.results[0].byline,
   content: res.data.results[0].short_desc + new_main_content_string,
   id: res.data.results[0].id,
   image: res.data.results[0].thumbnail,
   title: res.data.results[0].title,
   slug:'/'+res.data.results[0].slug,
   image_caption: res.data.results[0].image_caption
 })
 // setAdvData({
 //         adv_1: res.data.adv[0].adv_1,
 //         adv_2: res.data.adv[0].adv_2
 //            }
 // )
 const slideDatta = res.data.slideContent.slice(0, 1).map((slide) => {
  return {
    id: slide.id,
    title: slide.title,
    linkUrl: slide.linkUrl,
    linkIdUrl: slide.linkUrl
  }
})
   console.log("Your new array of modified objects here")
// setName((data)=>{
//
//   // return [...data, cateData];
// });
setSlideData(slideDatta);
})
.catch(err => { console.log('Google api calendar error', err) })
}  
 useEffect(() => {
   setParamValue(newsId)
   LatestData();
   scrollToTop();
getData();
const toggleVisibility = () => {
   if (window.pageYOffset > 500) {
     setIsVisible(true);
   } else {
     setIsVisible(false);
   }
 };

 window.addEventListener("scroll", toggleVisibility);

 return () => window.removeEventListener("scroll", toggleVisibility);

}, [newsId])

    return(
        <>
         <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>IANS LIVE-{collectionData.title ? collectionData.title.toUpperCase() : ''}</title>
        <meta data-meta-title="IANS LIVE" name="keywords" content={collectionData.keywords ? collectionData.keywords.toUpperCase() : ''} />
        <meta data-meta-title="IANS LIVE" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
        </Helmet>
   <div id="wrapper" className="wrapper">
      <div id="main_content" className="footer-fixed">
      <Header />
         <main>
            <section className="rt-sidebar-section-layout-2">
               <div className="container">
                  <div className="row gutter-40 sticky-coloum-wrap">
                     <div className="col-xl-9 sticky-coloum-item">
                        <div className="rt-left-sidebar-sapcer-5">
                           <div className="rt-main-post-single grid-meta">
                              <div className="post-header">
                                 <h2 className="title">
                                 {collectionData.title}
                                 </h2>
                                 <div className="post-meta">
                                    <ul>
                                       <li>
                                          <span className="rt-meta">
                                             by <NavLink to="#" className="name">{collectionData.byline}</NavLink>
                                          </span>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <figure className="post-img">
                                 <img src={collectionData.image} alt={collectionData.image_caption} width="960" height="520" />
                              </figure>
                              <div className ="" dangerouslySetInnerHTML={{ __html:  collectionData.content }} />
                           </div>
                        </div>
                     </div>
                     <Sidebar data={latData} />
                  </div>
               </div>
            </section>
         </main>
      <Footer/>
      </div>

<a href="javascript:void(0)" id="back-to-top">
   <i className="fas fa-angle-double-up"></i>
</a>
</div>        
        </>
    )
}

export default Detail