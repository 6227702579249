import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import Sidebar from './Sidebar';
import {Helmet} from "react-helmet";

const Detail = () =>{
const {newsId} = useParams();
const [isLoading, setIsLoading] = useState(false);
const [paramValue, setParamValue] = useState(null);
const [newsData, setName] = useState([]);
const [collectionData, setCollectionData] = useState([]);
const [slideData, setSlideData] = useState([]);
const [latData, setLatestData] = useState({
   id: null,
   title: '',
   byline: '',
   content: '',
   image: '',
   slug:'',
   image_caption: ''
});
const LatestData =()=>{
   axios
   .get(
   `https://cms.iansnews.in/api/news/portals/?language=english&website=2`
   )
   .then((res) => {
   const latestStories =
       res.data.results &&
       res.data.results.length > 0 &&
       res.data.results.slice(0, 8).map((item) => {
       return {
           byline: item.byline,
           content: item.short_desc,
           id: item.id,
           image: item.thumbnail,
           title: item.title,
           slug:'/'+item.slug,
           image_caption: item.image_caption,
       };
       });
   setLatestData(latestStories);
   });
}
 useEffect(() => {
   setParamValue(newsId)
   LatestData();
   const getData = async() =>{
      const apiD= `https://cms.iansnews.in/api/news/portals/${newsId}`
      axios.get(apiD)
  .then(res =>
     {

      let main_content_string = res.data.results[0].content;
      let iansIndex = main_content_string.indexOf("--IANS") ? main_content_string.indexOf("--IANS") : main_content_string;
      let new_main_content_string = main_content_string.substring(0, iansIndex);
    
    
      setCollectionData({
      byline: res.data.results[0].byline,
      content: res.data.results[0].short_desc + new_main_content_string,
      id: res.data.results[0].id,
      image: res.data.results[0].thumbnail,
      title: res.data.results[0].title,
      slug:'/'+res.data.results[0].slug,
      image_caption: res.data.results[0].image_caption
    })
    // setAdvData({
    //         adv_1: res.data.adv[0].adv_1,
    //         adv_2: res.data.adv[0].adv_2
    //            }
    // )
    const slideDatta = res.data.slideContent.slice(0, 1).map((slide) => {
     return {
       id: slide.id,
       title: slide.title,
       linkUrl: slide.linkUrl,
       linkIdUrl: slide.linkUrl
     }
  })
      console.log("Your new array of modified objects here")
  // setName((data)=>{
  //
  //   // return [...data, cateData];
  // });
  setSlideData(slideDatta);
})
.catch(err => { console.log('Google api calendar error', err) })
}
getData();
}, [newsId])
const printNews =()=>{
  window.print();
}
const shareinsocialmedia=(url)=>{
  window.open(url,'sharein','toolbar=0,status=0,width=648,height=395');
	return true;
}
    return(
        <>
         <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>IANS LIVE-{collectionData.title ? collectionData.title.toUpperCase() : ''}</title>
        <meta data-meta-title="IANS LIVE" name="keywords" content={collectionData.keywords ? collectionData.keywords.toUpperCase() : ''} />
        <meta data-meta-title="IANS LIVE" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
        </Helmet>
   <div id="wrapper" className="wrapper">
      <div id="main_content" className="footer-fixed">
      <Header />
      <main>
	<section className="rt-main-post-section main-post-section-style-1 section-padding bg--gray">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<h2 className="rt-section-heading"><span className="rt-section-text">ABOUT US</span><span className="rt-section-dot"></span><span className="rt-section-line"></span></h2>
					<h4>The IANS Edge</h4>
					<p>With India's growing importance in international strategic and economic thinking, news about the country is becoming more critical to the world. IANS provides news and analyses from India and South Asia from the region's perspective.</p>
					<p><strong>IANS</strong> was established in 1986, initially to serve as an information bridge between India and its thriving Diaspora in North America.</p>
					<p>Three decades later, it is a full-fledged wire agency, putting out news 24x7 from India and South Asia, and covering events of interest to this region from around the world.</p>
					<p>IANS is, however, more than just India's largest independent news service. It is now the preferred source for all manner of content and information.</p>
					<p>A media powerhouse, IANS is divided into six business verticals: IANS News Service (English &amp; Hindi), IANS Multimedia, IANS Publishing, IANS Media Consultancy, IANS Software Services, IANS Mobile Services. Each vertical is headed by a distinguished professional with strong domain expertise.</p>
					<p>The IANS client list includes practically every major print publication in India, top television news channels, websites, mobile operators, ethnic publications abroad, central and state government ministries and departments, overseas Indian missions, corporates and public policy institutions.</p>
					<p>IANS is today one of India's most reliable, independent and professional news wholesalers, providing high quality content across all media platforms.</p>
					
					<div className="contact-details">
					<p><strong>For More Information:</strong><br /><br /><strong>IANS India Pvt Ltd</strong><br />A-6, A Block <br />Sector-16,<br />Noida-201301 U.P. <br /><i className="fa fa-phone" aria-hidden="true"></i> +91-120-4822400 <br /><i className="fa fa-fax" aria-hidden="true"></i> +91-120-4822403, +91-120-4822404<br /></p>
					</div>
				</div>
			</div>
		</div>
	</section>
</main>
      <Footer/>
      </div>

<a href="javascript:void(0)" id="back-to-top">
   <i className="fas fa-angle-double-up"></i>
</a>
</div>        
        </>
    )
}

export default Detail